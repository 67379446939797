<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="form-row justify-content-end">
          <div class="col-md-4">
            <input type="search" class="form-control d-inline-block" placeholder="Pesquisar..." id="filterInput"
              v-model="filters.name" />
          </div>

          <div class="col-md-1">
            <button @click="getData()" type="button" class="btn btn-block btn-primary">
              <i class="bi bi-search"></i>
            </button>
          </div>
        </div>
      </div>

      <b-table sticky-header class="position-relative" :items="dataItems" responsive :fields="tableColumns"
        primary-key="id" show-empty empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado">
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(cost_center)="{ item }">
          {{ item.cost_center.title }}
        </template>

        <template #cell(apportionment)="{ item }">
          {{ item.apportionment.title }}
        </template>

        <template #cell(actions)="data">
          <div
            v-if="
              $can('Pedido de Compra - Histórico PC - Editar', 'Financeiro - ADM') || 
              $can('Pedido de Compra - Histórico PC - Excluir', 'Financeiro - ADM')
            "
            class="form-row align-items-center justify-content-center"
          >
            <div v-if="$can('Pedido de Compra - Histórico PC - Editar', 'Financeiro - ADM')" class="col-md-6 text-center">
              <feather-icon :id="`customer-${data.item.uuid}-delete-icon`" icon="TrashIcon"
                class="cursor-pointer cursor text-danger" size="16" @click="confirmDelete(data.item.uuid)" />
            </div>
            <div v-if="$can('Pedido de Compra - Histórico PC - Excluir', 'Financeiro - ADM')" class="col-md-6 text-center">
              <feather-icon :id="`customer-${data.item.uuid}-edit-icon`" icon="EditIcon" class="cursor-pointer cursor"
                size="16" @click="modalEdit(data.item)" />
            </div>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable :rows-table="rowsTable" :current-page="filters.currentPage" :per-page="filters.perPage"
        :total-rows="totalRows" />

      <b-modal id="modal-create-order-purchase-plan" modal-class="modal-create" :title="titleModal" centered hide-footer>
        <b-form @submit.prevent="submitCreate">
          <div class="form-row">
            <div class="col-md-8">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Nome
              </label>
              <div class="form-group">
                <b-form-input v-model="item.name" :class="{ 'is-invalid': $v.item.name.$error }" />
              </div>
            </div>

            <div class="col-md-4">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Rateio
              </label>
              <div class="form-group">
                <v-select label="title" item-text="title" item-value="code" v-model="item.apportionment"
                  :options="optionsApportionments" :class="{ 'is-invalid': $v.item.apportionment.$error }"
                  :searchable="false">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-4">
              <label for="">
                Operação
              </label>
              <div class="form-group">
                <v-select label="title" item-text="title" item-value="code" v-model="item.operation"
                  :options="optionsOperations" :searchable="false">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-8">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Centro de custo
              </label>
              <div class="form-group">
                <v-select label="title" item-text="title" item-value="code" v-model="item.costcenter"
                  :options="optionsCostCenter" :class="{ 'is-invalid': $v.item.costcenter.$error }" :searchable="false">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-4">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Status
              </label>
              <div class="form-group">
                <v-select label="title" item-text="title" item-value="code" v-model="item.active" :options="optionsStatus"
                  :class="{ 'is-invalid': $v.item.active.$error }" :searchable="false">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
          </div>

          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-7 col-12">
              <button type="button" @click="$bvModal.hide('modal-create-order-purchase-plan')"
                class="btn btn-light float-left">
                Fechar
              </button>
              <ButtonsActionsFooter variant="success" :submited="submited" :text="textSubmit" subtext="Aguarde..." />
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BSpinner,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";

export default {
  components: {
    BCard,
    CustomPaginateTable,
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
    BSpinner,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  data() {
    return {
      filters: {
        currentPage: 1,
        perPage: 100,
        name: "",
      },
      uuid: null,
      textSubmit: "",
      submited: false,
      filter: null,
      filterOn: [],
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "apportionment",
          label: "Rateio",
          class: "text-center",
          thStyle: "width: 120px",
        },
        {
          key: "cost_center",
          label: "Centro de custo",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Cadastrado",
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 120px",
        },
      ],
      dataItems: [],
      titleModal: "",
      item: {
        name: "",
        operation: "",
        apportionment: "",
        active: "",
        costcenter: "",
      },
      optionsOperations: [],
      optionsApportionments: [
        { code: 0, title: "Não" },
        { code: 1, title: "Sim" },
      ],
      optionsStatus: [
        { code: 0, title: "Inativo" },
        { code: 1, title: "Ativo" },
      ],
      optionsCostCenter: [],
    };
  },
  validations: {
    item: {
      name: {
        required,
      },
      costcenter: {
        required,
      },
      apportionment: {
        required,
      },
      active: {
        required,
      },
    },
  },
  mounted() {
    this.getData();
    this.$store.dispatch("OrderPurchase/forSelectOperations").then((res) => {
      this.optionsOperations = res;
    });

    this.$store.dispatch("CostCenter/forSelect").then((res) => {
      this.optionsCostCenter = res;
    });

    this.$root.$on("open:modal-create-order-purchase-plan", () => {
      this.clearForm();
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Plano de compra";
      this.$bvModal.show("modal-create-order-purchase-plan");
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-order-purchase-plan");
  },
  methods: {
    modalEdit(element) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";
      this.clearForm();

      this.item = element;
      this.$bvModal.show("modal-create-order-purchase-plan");
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("OrderPurchase/storePlan", this.item)
            .then(() => {
              this.notifyDefault("success");
              this.getData();
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-order-purchase-plan");
                this.clearForm();
              });
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("OrderPurchase/updatePlan", {
              uuid: this.item.uuid,
              data: this.item,
            })
            .then(() => {
              this.getData();
              this.notifyDefault("success");
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-order-purchase-plan");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    clearForm() {
      this.item = {
        name: "",
      };
    },
    async getData() {
      this.$store
        .dispatch("OrderPurchase/allPlans", this.filters)
        .then((res) => {
          const data = res;
          this.dataItems = data.data;
          this.rowsTable = data.data.length;
          this.totalRows = data.meta.total;
          this.filters.currentPage = data.meta.current_page;
        });
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("OrderPurchase/deletePlan", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
};
</script>
